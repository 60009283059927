// Skills.js
import React from 'react';
import resumeData from '../data/resumeData';

const Skills = () => {
  return (
    <div id="skills">
      <h2 className="section-header">Core Skills</h2>
      <ul>
        {resumeData.skills.map((skill, index) => (
          <li  className="details" key={index}>{skill}</li>
        ))}
      </ul>
    </div>
  );
};

export default Skills;
