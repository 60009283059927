import './SidebarHeader.css'
import darkLogo from '../darkLogo.png'
import lightLogo from '../lightLogo.png'

import React from 'react';

const SidebarHeader = ({ collapsed = true, isDark }) => {

  var style = 'StyledLogo-'+(isDark? "dark":"light");
  var Logo = isDark? darkLogo : lightLogo;
  return (
    <div className='StyledSidebarHeader' style={collapsed? ({padding:"20px"}):({padding:"15px"})}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontWeight: 700, color: "#ffffff" }}>
          {collapsed ?
            (<a href="/" style={{textDecoration:"none"}}><div className={style}>H</div></a>
            ):
              (<img src={Logo} width={215} alt="React Logo"/>)
          }
          </div>
      </div>
    </div>
  );
};

export default SidebarHeader;