const resumeData = {
  name: "Hossam Katory",
  title: "Solutions Architect",
  summary: "With over two decades of experience in information technology, I bring a wealth of expertise in application and solutions architecture, cloud architecture, software development, and team leadership. Beginning my career as a Software Engineer with a bachelor’s degree in computer engineering, I quickly advanced into roles where I spearheaded innovative solutions and drove excellence. My proficiency includes TOGAF, ITIL, SAFe, service-oriented architecture, microservices architecture, and hybrid cloud architecture, which I've applied to successfully modernize applications, migrate to the cloud, develop Big Data and AI solutions, and respond to complex requirements. My leadership has been instrumental in delivering projects on time and budget, and I've had the privilege of working with esteemed organizations such as the Ministry of Social Development, Land Information New Zealand, and IBM Corporation. As a Sr. Solutions Architect and OpenGroup Certified Master IT Specialist, I excel in translating business goals into IT solutions. My experience spans Core Banking and Government sectors, enriching my ability to deliver exceptional results",
  homeIntro: "With over two decades of experience in the field of information technology, I have developed a comprehensive skill set that spans application and solutions architecture, cloud architecture, software development, team leadership, and more. My journey began with a Bachelor's degree in Computer Engineering, setting the foundation for a career focused on innovation and excellence. <br />Throughout my professional journey, I have honed my expertise in various areas, including TOGAF, ITIL, SAFe, service-oriented architecture, microservices architecture, and hybrid cloud architecture. I have a proven track record in application modernization, cloud migration, BigData and analytics solutions. AI solutions, requirements analysis, and RFP responses. My leadership skills have been instrumental in guiding teams towards successful project delivery, while my technical acumen has enabled me to design and implement solutions for complex technical challenges. I have had the privilege of working on diverse projects for prestigious organizations, including the Ministry of Social Development, Land Information New Zealand, IBM Corporation, and more. My role in these projects has ranged from senior solutions architect to managing consultant, where I have led teams, overseen cloud migration projects, and contributed to the development of cutting-edge solutions.",
  experience: [
    {
      title: "Senior Solutions Architect",
      date: "August 2022 – Feb 2024",
      company: "Ministry of Social Development",
      description: "Primarily looking after the Architecture for the Straight to Processing application, which is an Oracle Application Express based application handling MSD tasks processing, creating the architecture for the application integration and data flow with various internal and external system like Genesys OCS, Rails, IBM Integration Bus, etc., additionally, designed the architecture for integration with cloud-based services, i.e. Microsoft Exchange Online, application migration plan to MS Azure cloud, workflow migration from IBM BPM to APEX, and migrating user authentication from Kerberos to Windows Hello for Business using OpenID. \n Additionally, I looked after the architecture of the Fleet Management System replacement from Rackspace Smartrack solution to AWS hosted Procon solution, including data security and privacy reviews. And I oversee the solution architecture of the MSD Social services providers accreditation system modernisation from the legacy application to a Salesforce/AWS based application.",
    },
    {
      title: "Senior Solutions Architect",
      date: "Jan 2022 – August 2022",
      company: "Land Information New Zealand",
      description: "Create the new solution architecture of the LINZ Survey application to modernise it from the legacy desktop application to web based, cloud native solution utilising the micro-services architecture and AWS hybrid cloud architecture using SAFe agile framework.",
    },
    {
      title: "Solutions Architect",
      date: "April 2018 – Jan 2022",
      company: "IBM Corporation, New Zealand",
      description: "Managing Consultant, Cloud and Cognitive Solution Architect, oversighting IBM Watson cognitive and analytics projects, cloud migration projects, and leading the delivery teams.",
    },
    {
      title: "Application Architect",
      date: "Oct 2014 – March 2018",
      company: "IBM Corporation, New Zealand",
      description: "Managing Consultant, Cognitive Applications Architect, oversighting IBM Watson AI projects and leading the delivery and support teams.",
    },
    {
      title: "Package Solution Consultant",
      date: "June 2013 – June 2014",
      company: "IBM Corporation, Kenya",
      description: "Managing Consultant, Oversighting IBM Tivoli deals for private cloud, service management and enterprise asset management software solutions delivery for IBM customers in Europe, Middle East, and Africa. and hire and build service delivery team in Kenya to serve the East Africa region.",
    },
    {
      title: "Staff Software Engineer, Development lead",
      date: "Nov 2011 - June 2013",
      company: "IBM Corporation, Egypt",
      description: "Snr. Software Engineer and development lead, leading the software globalisation project for HESN health care solution for Ministry of Health in Kingdom of Saudi Arabia and the software modernisation of Panorama Health Care solution of British Columbia and Ontario, Canada.",
    },
    {
      title: "Staff Software Engineer",
      date: "Jan 2005 – Oct 2011",
      company: "IBM Corporation, Egypt",
      description: "Snr. Software Engineer and development lead, leading the software globalisation assessment and development for the IBM Tivoli brand products, mainly IBM Maximo, IBM Control Desk and Netcool suite.",
    },
    // Add more experience items as needed
  ],
  skills: [
    "Application/Solutions Architecture",
    "TOGAF, SAFe, ITIL",
    "Service-Oriented Architecture and Complex Systems integration",
    "Micro-Services Architecture",
    "Domain Driven Architecture",
    "Event Driven Architecture",
    "Hybrid Cloud Architecture",
    "Application modernisation and cloud migration.",
    "Cloud Solutions design (AWS, Azure, IBM Cloud)",
    "Requirements Analysis and RFP responses.",
    "Team Leadership and Mentoring",
    "Software Development",
    "Agile development methodologies",
    "Enterprise asset management, Service Management, ITSM",
    "Strategic planning",
    // Add more skills
  ],
  certifications: [
    "AWS Solutions Architect",
    "ICAgile Certified Professional",
    "Microsoft Certified: Azure Fundamentals",
    // Add more certifications
  ],
  contact: {
    phone: "+64 21 173 6504",
    email: "HossamKatory@outlook.com",
    linkedin: "http://nz.linkedin.com/in/hossamkatory",
  },

  projects: [
    {
      project_title: "Repid Deployment Team - APEX Project",
      client: "Ministry of Social Development",
      role: "Senior Solutions Architect",
      description: "Leading the architecture work for the Oracle APEX team to deliver various business and technical requirements for MSD, including Contact Centre integration, Application integration with MS365 Exchange Online, Azure Cloud Migration pilot. Etc.",
    },
    {
      project_title: "Fleet Management",
      client: "Ministry of Social Development",
      role: "Senior Solutions Architect",
      description: "I oversaw after the architecture work of the Fleet Management System replacement from Rackspace Smartrack solution to AWS hosted Procon solution, while complying with MSD security and privacy requirements.",
    },
    {
      project_title: "Taiki Project",
      client: "Ministry of Social Development",
      role: "Senior Solutions Architect",
      description: "I oversaw the solution architecture of the MSD Social services providers accreditation system modernisation from the legacy application to a Salesforce/AWS based application.",
    },
    {
      project_title: "STEP programme - LandOnline",
      client: "Land Information New Zealand",
      role: "Senior Solutions Architect",
      description: "as Senior Solutions Architect I created the new solution architecture of the LINZ Survey application to modernise it from the legacy desktop application to web based, cloud native solution utilising the micro-services architecture and AWS hybrid cloud architecture",
    },
    {
      project_title: "RiskInSite migration to AWS",
      client: "ASB Bank",
      role: "Solution Lead Architect",
      description: "I was the IBM lead Architect for the project, which was ASB response to comply with Reserve Bank of New Zealand revised BS11 outsourcing policy requiring the large banks in New Zealand to separate their IT systems from their offshore parent entities. My role was to design the solution architecture, review the infrastructure architecture and Low Level design, to separate and migrate the risk management solution OpenPages used by ASB bank to an independent instance from CBA bank running on AWS Cloud and managed by IBM, oversees the solution Application, Infrastructure implementation, and CICD process architecture and implementation, and work with ASB Bank and CBA Bank to build the solution and the required integrations with CBA and ASB.",
    },
    {
      project_title: "Cognitive HR",
      client: "Telstra Group",
      role: "Senior Solutions Architect",
      description: "Designed a system based on IBM Watson Discovery Service and Watson Assistant to enable users to search for answers using natural language queries, allowing Telstra employees to ask a chatbot about their annual performance assessment reports, vacations, salary appraisals, etc. and provides answers based on articles and documents from the internal HR site",
    },
    {
      project_title: "Cognitive Enterprise solutions Investigation",
      client: "Telstra Group",
      role: "Senior Solutions Architect",
      description: "Designed a system based on IBM Watson Discovery Service and Watson Assistant enabling Telstra enterprise customers to ask the solution to troubleshoot the issues they face for IP based communication devices or the setup process in natural language and get the answers directly based on the various documents, knowledgebase articles, and FAQ documents in Telstra support sites and repositories.",
    },
    {
      project_title: "AIA Infinity Project",
      client: "AIA Insurance",
      role: "Solutions Architect",
      description: "After AIA Insurance acquisition of Sovereign insurance from ASB Bank to re-brand it as AIA Insurance New Zealand. AIA launched a large and complex project to migrate IT systems and Infrastructure from ASB Bank systems to AIA IT Infrastructure. Hossam Lead and coordinated cloud migration efforts to migrate Insurance sales, quotation and application systems running on Salesforce.com and ASB Data centres to new Salesforce tenancy and AWS cloud and integration with AIA instances. Hossam was responsible for designing the technical solutions for AWS and Salesforce integration with AIA Backend systems. Facilitate integration and migration, integration defects triage and resolutions as well as coordination with and supervising other vendors developing, supporting the application development and migration.",
    },
    {
      project_title: "Investigation Search tool",
      client: "New Zealand Police",
      role: "Senior Solutions Architect",
      description: "Designed the solution to enable serious crimes investigators to search and find case files and evidence files across more than four different police file repositories (Windows Shared drives, MS SharePoint, custom secure file repositories, and more.) with over 250TBs of data, saving time and effort for the investigators to find the files they need while adhering to the strict secure data access rights and privileges. With the ability to add more integrations for more data repositories. During the solution implementation Hossam lead the implementation team and the stakeholders’ communications, Hossam lead the team to deliver the working proof of concept in less than 6 weeks which was used by Police Investigators in New Plymouth as a pilot.",
    },
    {
      project_title: "Westpac One Online banking",
      client: "Westpac New Zealand",
      role: "Application Architect",
      description: "<div><li>Redefine the Westpac One BigData system architecture to migrate from the legacy on-prem Watson Explorer based solution to a cloud-based solution using Watson Discovery, Cloud Pak for Data, IBM DB2, and AWS cloud.</li><li>Hossam was Watson Explorer application architect and support team leader for Westpac New Zealand where I lead the support team for 3M NZD per annum project to ensure 24/7 system availability while coordinating with Westpac business leadership, IT Infrastructure management team, frontend application development team, and other stakeholders.</li><li>Hossam designed solutions for Westpac NZ Big Data and Westpac One online banking to increase system capacity to support the growing number of customer and data, and to add new innovative system features and capabilities.</li><li>Hossam lead the Implementation of Watson Explorer backend solution for Westpac One online banking system.</li><li>Designed and implemented Westpac Kiwi Saver and Wealth Management integration and migration to Westpac One.</li><li>Automated Westpac One online banking system backend maintenance which saves 10+ hours of support team overtime on monthly bases and eliminated possible human errors.</li><li>Define the new WestpacOne - Watson Explorer Architecture to support the increasing data and user query load saving over 2M NZD in hardware upgrade costs to achieve the same performance with the old architecture.</li><li>Design, plan, and lead the migration from Watson Explorer v9.0 to v11 for Westpac.</li><li>Working with the environment stakeholders and infrastructure support teams planning the solution upgrade plans to allow the ever-expanding customer transactions and queries.</li></div>",
    },
    {
      project_title: "IBM Control Desk",
      client: "Eni S.p.A, Italy",
      role: "Senior Package Consultant",
      description: "Design and implement the Service desk tool for Eni S.p.A using IBM Control desk and customise the tickets workflows following the company’s processes and the ITL processes.",
    },
    {
      project_title: "Panorama system - Health Infoway",
      client: "Canada Health Infoway, BC & Ontario",
      role: "Senior Software Engineer - Development Lead",
      description: "Canada Health Infoway Panorama was a solution for public health surveillance and outbreak response built in response to SARS outbreak. Hossam was a scrum master and development team lead building and modernising the application, which was built on old technologies like struts. In addition to new features for data privacy and security for Aboriginals’ health records.",
    },
    {
      project_title: "HESN Healthcare solution",
      client: "Ministry of Health, KSA",
      role: "Senior Software Engineer - Development Lead",
      description: "Designed the health care solution Arabic language Support and adaption from the original Canadian solution, Hossam was the project scrum master, and development lead for five senior developers delivering the IBM Public health solution (a.k.a. Panorama) project with $1.6M USD project budget with was part of 22M USD deal between IBM and KSA Ministry of Health",
    },
    {
      project_title: "IBM Maximo upgrade",
      client: "SAB Miller",
      role: "Senior Package Consultant",
      description: "Consulted SAB Miller for the application upgrade and migration process to upgrade from Maximo 7.1 to Maximo 7.5 and the process to validate the impact of the release upgrade on the custom processes and application created by the client.",
    },
    {
      project_title: "ITSM Project",
      client: "Etihad Airways",
      role: "Senior Package Consultant",
      description: "Designed and implementation the PoC for IBM Control Desk. IBM Maximo and IBM TADDM for Etihad Airways to manage their IT assets and IT services as part of 200M USD deal between IBM and Etihad Airways.",
    },
    {
      project_title: "IBM Maximo",
      client: "IBM Maximo",
      role: "Senior Software Engineer - Development Lead",
      description: "<div><li>Designed the application Bidirectional languages support for Arabic and Hebrew languages and was the development lead of four senior developers implementing the Bidi support in Maximo Base Services, Adapter Framework, and Integration Composer.</li><li>Application globalisation development lead and was responsible to communicate and coordinate the team effort and progress with other feature development teams and product stakeholders.</li><li>Hossam designed the multi-Calendars support in Maximo UI framework and Dojo Toolkit adaptation and implementation in Maximo 7.5 Async project.</li></div>",
    },

  ],
};

export default resumeData;
