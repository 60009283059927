// Summary.js
import React from 'react';
import resumeData from '../data/resumeData';

const Summary = ({ summary }) => {
  return (
    <div id="summary">
       <h2 className="section-header">About</h2>
      <p className="details">{resumeData.summary}</p>
    </div>
  );
};

export default Summary;
