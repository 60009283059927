import React from "react";
import { Sidebar, Menu, SubMenu, MenuItem, menuClasses } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SidebarHeader from "./SidebarHeader";
import sectionsData from '../data/sectionsData';
import SidebarFooter from './SidebarFooter';
import { Theme, Themes } from './Themes';



const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const AppSidebar = ({ theme, changeHandler }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [broken, setBroken] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);

  var isDark = (theme === Theme.DARK);

  const menuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: Themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: Themes[theme].menu.disabled.color,
      },
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(Themes[theme].menu.menuContent, !collapsed ? .8 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: Themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(Themes[theme].menu.hover.backgroundColor, 1),
        color: Themes[theme].menu.hover.color,
      },
    },
    label: function ({ open }) {
      return {
        fontWeight: open ? 600 : undefined,
      };
    },
  };

  return (

    <div className="flex-item-left flex-nowrap sidebar ">
      <div style={{ marginBottom: '16px' }}>
        {broken && (
          <button className={(isDark ? "StyledSidebarDarkIcon" : "StyledSidebarLightIcon") + " sb-button"} onClick={() => setToggled(!toggled)}>
            {<MenuOutlinedIcon />}
          </button>
        )}
      </div>
      <Sidebar
        collapsed={collapsed}
        breakPoint="md"
        toggled={toggled}
        onBreakPoint={setBroken}
        onBackdropClick={() => setToggled(false)}
        style={{ height: "100hv" }}
        backgroundColor={hexToRgba(Themes[theme].sidebar.backgroundColor, 1)}
        rootStyles={{
          color: Themes[theme].sidebar.color,
        }}
        className="sidebar">
        <div style={{ flex: 1, marginBottom: '' }}>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MenuOutlinedIcon />}
              onClick={() => {
                setCollapsed(!collapsed)
              }
              }
              style={{ textAlign: "center" }}
            >
              {" "}
            </MenuItem>
            <SidebarHeader style={{ marginBottom: '24px', marginTop: '16px' }} collapsed={collapsed} isDark={isDark} />

            {sectionsData.sections.map((section, index) => (
              section.subSectons ?
                <SubMenu key={index}
                  label={section.title}
                  icon={section.icon()}>
                  {section.subSectons.map((subitem, sindex) => (
                    <MenuItem
                      key={sindex}
                      icon={subitem.icon()}
                      component={<a index={sindex} href={subitem.url}target={subitem.external ? '_blank' : ""} rel={subitem.external ? "noopener noreferrer" : ""}>{subitem.title}</a>}>
                      {subitem.title}
                    </MenuItem>
                  ))}
                </SubMenu>
                :
                <MenuItem
                  key={index}
                  icon={section.icon()}
                  component={<a index={index}
                    href={section.url} target={section.external ? '_blank' : ""} rel={section.external ? "noopener noreferrer" : ""}>{section.title}</a>
                  }>
                  {section.title}
                </MenuItem>
            ))}
          </Menu>
        </div>
        <SidebarFooter changeHandler={changeHandler} collapsed={collapsed} isDark={isDark} />
      </Sidebar>
    </div>

  );
}
export default AppSidebar;