// Certifications.js
import React from 'react';
import resumeData from '../data/resumeData';

const Certifications = () => {
  return (
    <div id="certifications">
      <h2 className="section-header">Key Certifications</h2>
      <ul>
        {resumeData.certifications.map((cert, index) => (
          <li className="details" key={index}>{cert}</li>
        ))}
      </ul>
    </div>
  );
};

export default Certifications;
