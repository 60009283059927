// Projects.js
import React from 'react';
import { Container } from 'react-bootstrap';
import resumeData from '../data/resumeData';
import './Projects.css'
import '../App.css'


const Projects = () => {
  return (
    <Container fluid  className="projects" id="projects">
      <h2>Project History</h2>
      <ul>
        {resumeData.projects.map((project, index) => (
          <div>
            <h3 className="project-client" >{project.client}</h3>
            <h4 className="project-role" >{project.role}</h4>
            <h5 className="project-title" >{project.project_title}</h5>
            <p><div className="project-desc" dangerouslySetInnerHTML={{__html:project.description}} /></p>
          </div>
        ))}
      </ul>
    </Container>
  );
};

export default Projects;
