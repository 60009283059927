export const books = {

    list: [
        {
            title: "Monolith to Microservices: Evolutionary Patterns to Transform Your Monolith",
            description: "How do you detangle a monolithic system and migrate it to a microservice architecture? How do you do it while maintaining business-as-usual? As a companion to Sam Newman’s extremely popular Building Microservices, this new book details a proven method for transitioning an existing monolithic system to a microservice architecture. With many illustrative examples, insightful migration patterns, and a bevy of practical advice to transition your monolith enterprise into a microservice operation, this practical guide covers multiple scenarios and strategies for a successful migration, from initial planning all the way through application and database decomposition. You’ll learn several tried and tested patterns and techniques that you can use as you migrate your existing architecture.",
            category: "Architecture",
            url: "https://amzn.to/3Vy0krw",
            img: "https://m.media-amazon.com/images/I/91yYn6cJaLL._SL1500_.jpg",

        },
        {
            title: "Building Microservices: Designing Fine-Grained Systems",
            description: "As organizations shift from monolithic applications to smaller, self-contained microservices, distributed systems have become more fine-grained. But developing these new systems brings its own host of problems. This expanded second edition takes a holistic view of topics that you need to consider when building, managing, and scaling microservices architectures.",
            category: "Architecture",
            url: "https://amzn.to/3RtwGSX",
            img: "https://m.media-amazon.com/images/I/81dmHMoJDjL._SL1500_.jpg",

        },
        {
            title: "Software Architecture in Practice",
            description:"",
            category: "Architecture",
            url: "https://amzn.to/3XqnxP7",
            img: "https://m.media-amazon.com/images/I/615FvRR9wuL._SL1500_.jpg",

        },
        {
            title: "Software Systems Architecture: Working With Stakeholders Using Viewpoints and Perspectives",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3xyS8iJ",
            img: "https://m.media-amazon.com/images/I/51VJKe5WXsL.jpg",
        },
        {
            title: "Building Event-Driven Microservices: Leveraging Organizational Data at Scale",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3Xvbfoz",
            img: "https://m.media-amazon.com/images/I/81vxv0BQLzL._SL1500_.jpg",
        },
        {
            title: "Software Architecture: The Hard Parts",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/4bbmc1V",
            img: "https://m.media-amazon.com/images/I/81sdcxLtlwL._SL1500_.jpg",
        },
        {
            title: "The Phoenix Project",
            description: "",
            category: "Project Management",
            url: "https://amzn.to/3xrBCkH",
            img: "https://m.media-amazon.com/images/I/810Ni+ALiWL._SL1500_.jpg",
        },
        {
            title: "The Software Architect Elevator: Redefining the Architect's Role in the Digital Enterprise",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/4etsxII",
            img: "https://m.media-amazon.com/images/I/71ngR++SKRL._SL1500_.jpg",
        },
        {
            title: "Thinking in Systems",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/45yu2S4",
            img: "https://m.media-amazon.com/images/I/51q+TMvkNFL.jpg",
        },
        {
            title: "Clean Architecture: A Craftsman's Guide to Software Structure and Design",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3VLQ3cK",
            img: "https://m.media-amazon.com/images/I/619ht2WrGTL._SL1045_.jpg",
        },
        {
            title: "Enterprise Integration Patterns: Designing, Building, and Deploying Messaging Solutions",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3XwnfX2",
            img: "https://m.media-amazon.com/images/I/61Q9mMOp4VL._SL1500_.jpg",
        },
        {
            title: "Patterns of Enterprise Application Architecture",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3VvhQwz",
            img: "https://m.media-amazon.com/images/I/61yNt+jcM0L._SL1500_.jpg",
        },
        {
            title: "Refactoring: Improving the Design of Existing Code",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/4csKYve",
            img: "https://m.media-amazon.com/images/I/71yPDDIwcoL._SL1500_.jpg",
        },
        {
            title: "Fundamentals of Software Architecture: An Engineering Approach ",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/3KO0xSr",
            img: "https://m.media-amazon.com/images/I/9193iMIxVTL._SL1500_.jpg",
        },
        {
            title: "Technology Strategy Patterns: Architecture as Strategy",
            description: "",
            category: "Architecture",
            url: "https://amzn.to/4c8t8hi",
            img: "https://m.media-amazon.com/images/I/81gQUQ9oC+L._SL1500_.jpg",
        },
        {
            title: "",
            description: "",
            category: "Architecture",
            url: "",
            img: "",
        },
       

    ],

};

export default books;