import React from 'react';
import './SidebarFooter.css'
import LightModeSharpIcon from '@mui/icons-material/LightModeSharp';
import NightlightRoundSharpIcon from '@mui/icons-material/NightlightRoundSharp';
import IconButton from '@mui/material/IconButton';


const SidebarFooter = ({ changeHandler, isDark, collapsed = true }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: '20px',
                position: 'absolute',
                bottom: '0',
                padding: '20px'
            }}

        >
           {/*collapsed ? "" :
                (<div className={(isDark ?
                    "StyledSidebarDarkFooter" :
                    "StyledSidebarLightFooter") + ' StyledSidebarFooter'}>
                    <b>Hossam Katory</b> <br /><i>Solutions Architect<br />Software Engineer<br /></i></div>)
            */}
            <div className={(isDark ?
                "StyledSidebarDarkFooter" :
                "StyledSidebarLightFooter")
                + ' StyledCollapsedSidebarFooter'}>
                <IconButton aria-label="delete" onClick={changeHandler}>
                    {isDark ? (
                        <LightModeSharpIcon style={{ color: "yellow" }} />) : (<NightlightRoundSharpIcon style={{ color: "white" }}/>)
                        
                    }
                </IconButton>
            </div>
        </div >
    );
};

export default SidebarFooter;