// Experience.js
import React from 'react';
import resumeData from '../data/resumeData';

const Experience = () => {
  return (
    <div id="experience">
      <h2 className="section-header">Work Experience</h2>
      <ul>
        {resumeData.experience.map((exp, index) => (
          <div key={index}>
            <h3 className="subsection">{exp.title}</h3>
            <p><b><i>{exp.company} </i></b>{exp.date}</p>
            <p className="details">{exp.description}</p>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Experience;
