import React from 'react';
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper';
import './Readings.css';
import books from '../data/books';

export const Readings = () => {
    return (

        <div className="readings" id="readings">
            <div style={{marginBottom:"80px"}}><span >
                <h1>Recommeded Readings</h1>
            </span>
            </div>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={8}>
                    {books.list.map((book, index) => (
                        <Grid key={index} item>
                            <Paper className="paper"><a href={book.url} target='_blank' rel="noopener noreferrer" >
                                <img src={book.img} alt={book.title} width="250" />
                            </a>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );

};
export default Readings;