// Home.js
import React from 'react';
import Summary from './Summary';
import Experience from './Experience';
import Skills from './Skills';
import Certifications from './Certifications';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <Container fluid className='about'>
      <Summary name="summary" />
      <Experience name="experience" />
      <Skills name="skills" />
      <Certifications name="certifications" />
    </Container>
  );
};

export default About;