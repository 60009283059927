export const Theme = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const Themes = {
  [Theme.LIGHT]: {
    body: {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    sidebar: {
      backgroundColor: '#dee0db',
      color: '#423a48',
    },
    menu: {
      menuContent: '#bec0be',
      icon: '#423a48',
      hover: {
        backgroundColor: '#9e9d9f',
        color: '#423a48',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  [Theme.DARK]: {
    body: {
      backgroundColor: "#2d2d2d",
      color: "#f5f5f5",
    },
    sidebar: {
      backgroundColor: '#202020',
      color: '#a17f50',
    },
    menu: {
      menuContent: '#353535',
      icon: '#a17f50',
      hover: {
        backgroundColor: '#5b472b',
        color: '#a17f50',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};
export default Themes;